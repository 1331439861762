import React, { useState, useEffect } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";

const Login = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Listen for changes in user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        localStorage.setItem("firebaseToken", user.accessToken); // Store token for API requests
      } else {
        setUser(null);
        localStorage.removeItem("firebaseToken");
      }
      setLoading(false);
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      setUser(result.user);

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      const response = await fetch(`${apiUrl}/auth/firebase-login/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`, // FIX: Add 'Bearer ' prefix
          "Content-Type": "application/json", // FIX: Explicitly set content type
        },
        body: JSON.stringify({ token: idToken }), // FIX: Send token in request body
      });

      const data = await response.json();
      console.log("Backend response:", data);
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out of Firebase
      setUser(null);
      localStorage.removeItem("firebaseToken"); // Clear the token
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message while checking auth state
  }

  return (
    <div>
      {user ? (
        <div>
          <p>Welcome, {user.displayName}</p> {/* Display user name */}
          <button onClick={handleLogout}>Logout</button> {/* Logout button */}
        </div>
      ) : (
        <button onClick={handleGoogleSignIn} className="google-signin-button">
          <img
            src={require("../google_logo.png")}
            alt="Google logo"
            className="google-logo"
          />
        </button>
      )}
    </div>
  );
};

export default Login;
