import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./index.css";
import "./styles.css";
import ArticleList from "./components/ArticleList";
import Profile from "./components/Profile";
import Header from "./components/Header"; // Import the new Header component
import { useAuth } from "./context/AuthContext";

function App() {
  const { user } = useAuth();
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem("darkMode");
    return saved === null ? true : saved === "true";
  });
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
    document.body.className = darkMode ? "dark-mode" : "";
  }, [darkMode]);

  return (
    <Router>
      <div className="app">
        {/* Header */}
        <Header
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />

        {/* Mobile Menu */}
        {menuOpen && (
          <div className="mobile-menu">
            <div className="mobile-toggles">
              {user && (
                <Link to="/profile" className="profile-link">
                  👤 Profile
                </Link>
              )}
            </div>
          </div>
        )}

        {/* Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ArticleList />
              </>
            }
          />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
