import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import axios from "axios";

const Messages = ({
  conversationId,
  articleId,
  messages = [], // Default to an empty array if no messages are passed
  loading,
  setMessages,
  setConversationId,
  showInputInitially,
  readOnly = false, // Defaults to false (editable mode)
}) => {
  const [newMessage, setNewMessage] = useState("");
  const { user, loading: authLoading } = useAuth();
  const inputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (
      !readOnly &&
      showInputInitially &&
      !loading &&
      !authLoading &&
      inputRef.current &&
      user // Only focus if user is logged in
    ) {
      inputRef.current.focus();
    }
  }, [showInputInitially, loading, authLoading, readOnly, user]);

  // Use useCallback to memoize the fetchMessages function
  const fetchMessages = useCallback(async () => {
    try {
      if (!hasMore) return; // Stop fetching if there are no more messages

      let apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      // Headers object - will include token only if user is logged in
      const headers = {};
      if (user) {
        const token = await user.getIdToken();
        headers.Authorization = `${token}`;
      }

      const response = await axios.get(
        `${apiUrl}/conversations/${conversationId}/messages/?page=${page}`,
        { headers }
      );

      // Updated to handle the new API response structure - removed 'count' from destructuring
      const { results, num_pages, next } = response.data;
      const newMessages = Array.isArray(results) ? results : [];

      setTotalPages(num_pages);
      setHasMore(next); // Use the next flag from API to determine if there are more pages

      setMessages((prevMessages) => {
        // Ensure prevMessages is always an array before proceeding
        const existingMessages = Array.isArray(prevMessages)
          ? prevMessages
          : [];
        const existingIds = new Set(existingMessages.map((msg) => msg.id));
        const uniqueMessages = newMessages.filter(
          (msg) => !existingIds.has(msg.id)
        );

        return [...existingMessages, ...uniqueMessages];
      });
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }, [conversationId, hasMore, page, setMessages, user]);

  useEffect(() => {
    // Fetch messages even for non-logged in users if conversationId exists
    if (conversationId) {
      fetchMessages();
    }
  }, [conversationId, page, fetchMessages]); // Added fetchMessages as a dependency

  const handleShowMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const createConversation = async () => {
    try {
      if (readOnly || !user) return;
      const token = await user.getIdToken();
      let apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      const response = await axios.post(
        `${apiUrl}/conversations/create/${articleId}/`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      );

      const newConversationId = response.data.id;
      setConversationId(newConversationId);
      return newConversationId;
    } catch (error) {
      console.error("Error creating conversation:", error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    if (readOnly || !newMessage.trim()) return;
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      const token = await user.getIdToken();
      let apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      let effectiveConversationId = conversationId;
      if (!conversationId || messages.length === 0) {
        effectiveConversationId = await createConversation();
      }

      const response = await axios.post(
        `${apiUrl}/conversations/${effectiveConversationId}/messages/create/`,
        { text: newMessage },
        {
          headers: { Authorization: `${token}` },
        }
      );

      // Add the new message to the beginning of the array
      setMessages((prevMessages) => [response.data, ...prevMessages]);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      if (!user) return;

      const token = await user.getIdToken();
      let apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      const response = await axios.delete(
        `${apiUrl}/messages/delete/${messageId}`,
        {
          headers: { Authorization: `${token}` },
        }
      );

      if (response.status === 204) {
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== messageId)
        );
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  // Handle enter key press for sending messages
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Loading state
  if (loading || authLoading) return <p>Loading messages...</p>;

  return (
    <div className="messages-container">
      <div className="messages-list">
        {messages.length > 0 ? (
          messages.map((msg) => (
            <div key={msg.id} className="message">
              <strong>{msg.user_name}</strong>: {msg.text} <br />
              <span className="timestamp">{msg.created_at}</span>
              {user && user.uid === msg.user && (
                <button
                  onClick={() => handleDeleteMessage(msg.id)}
                  className="delete-button"
                >
                  Delete
                </button>
              )}
            </div>
          ))
        ) : (
          <p>No messages found.</p>
        )}
      </div>

      {hasMore && messages.length > 0 && (
        <button onClick={handleShowMore} className="show-more-button">
          Show More
        </button>
      )}

      {/* Only show input when user is logged in and not in read-only mode */}
      {!readOnly && user && (
        <div className="message-input">
          <input
            ref={inputRef}
            type="text"
            value={newMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      )}

      {/* Show login prompt if no user but don't hide messages */}
      {!user && !readOnly && (
        <div className="login-prompt">
          <p>Please log in to join the conversation</p>
        </div>
      )}
    </div>
  );
};

export default Messages;
