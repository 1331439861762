import { useState } from "react";
import Messages from "./Messages";

const Conversation = ({ conversationId, articleId, messageCount }) => {
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [currentConversationId, setCurrentConversationId] =
    useState(conversationId); // Track the conversation ID

  const handleClick = () => {
    if (!showMessages && messages.length === 0) {
      setLoadingMessages(true);
      fetchMessages();
    }
    setShowMessages((prev) => !prev);
  };

  const fetchMessages = async () => {
    try {
      let apiUrl;
      if (process.env.NODE_ENV === "development") {
        apiUrl = "http://localhost:8000/api";
      } else {
        apiUrl = "/api";
      }

      // Only fetch messages if we have a conversationId
      if (currentConversationId) {
        const response = await fetch(
          `${apiUrl}/conversations/${currentConversationId}/messages/`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setMessages(data);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoadingMessages(false);
    }
  };

  return (
    <div className="conversation">
      <button onClick={handleClick} className="conversation-button">
        🗨 {messageCount}
      </button>

      {showMessages && (
        <Messages
          messages={messages}
          loading={loadingMessages}
          conversationId={currentConversationId}
          articleId={articleId} // Pass articleId for creating a conversation
          setMessages={setMessages}
          setConversationId={setCurrentConversationId} // Allow Messages to update conversationId
          showInputInitially={messages.length === 0}
        />
      )}
    </div>
  );
};

export default Conversation;
