import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Login from "./Login";

const Header = ({ menuOpen, setMenuOpen }) => {
  const { user } = useAuth();

  return (
    <header className="header">
      <h1>
        <Link to="/">
          <img
            src={require("../logo.webp")}
            alt="Uutislauta"
            className="logo"
          />
        </Link>
      </h1>
      <button onClick={() => setMenuOpen(!menuOpen)} className="menu-toggle">
        {menuOpen ? "✖" : "☰"}
      </button>
      <div className="desktop-toggles">
        {!user ? (
          <Login />
        ) : (
          <Link to="/profile" className="profile-link">
            👤 Profile
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
