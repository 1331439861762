import React, { useState, useEffect } from "react";
import axios from "axios";
import Conversation from "./Conversation";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState("");
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);

  useEffect(() => {
    fetchArticles();
  }, [page, showPaywall, selectedCategoryGroup]);

  const fetchArticles = async () => {
    try {
      const params = new URLSearchParams({
        page,
        show_paywall: showPaywall,
        ...(selectedCategoryGroup && { category_group: selectedCategoryGroup }),
      });

      const url =
        process.env.NODE_ENV === "development"
          ? `http://localhost:8000/api/?${params.toString()}`
          : `/api/?${params.toString()}`;

      const response = await axios.get(url);
      const data = response.data;

      setArticles(page === 1 ? data.articles : [...articles, ...data.articles]);
      setCategoryGroups(data.category_groups);
      setHasNext(data.has_next);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const handleVote = async (articleId, type) => {
    const url =
      type === "upvote" ? `/upvote/${articleId}/` : `/downvote/${articleId}/`;
    try {
      const response = await axios.post(url);
      setArticles(
        articles.map((article) =>
          article.id === articleId
            ? { ...article, [type + "s"]: response.data[type + "s"] }
            : article
        )
      );
    } catch (error) {
      console.error(`Error ${type}ing article:`, error);
    }
  };

  return (
    <main className="main">
      <div className="desktop-filter">
        <select
          value={selectedCategoryGroup}
          onChange={(e) => setSelectedCategoryGroup(e.target.value)}
        >
          <option value="">Kaikki kategoriaryhmät</option>
          {categoryGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>
      </div>

      <h2>Tuoreimmat uutiset</h2>
      <ul className="article-list">
        {articles.length ? (
          articles.map(
            (article) => (
              console.log(article),
              (
                <li key={article.id} className="article-item">
                  <div className="article-source">{article.source}</div>
                  <a href={article.link}>
                    {article.title} {article.paywall && <span>🔒</span>}
                  </a>
                  <div className="article-meta">
                    {article.upvotes}{" "}
                    <button onClick={() => handleVote(article.id, "upvote")}>
                      👍
                    </button>{" "}
                    {article.downvotes}{" "}
                    <button onClick={() => handleVote(article.id, "downvote")}>
                      👎
                    </button>
                  </div>
                  <div className="article-meta">{article.pub_date}</div>

                  {/* Show conversation */}
                  <Conversation
                    messageCount={article.message_count}
                    conversationId={article.conversation_id}
                    articleId={article.id}
                  />
                </li>
              )
            )
          )
        ) : (
          <li className="loading-container">
            <div className="spinner"></div>
          </li>
        )}
      </ul>

      {hasNext && (
        <div className="load-more">
          <button onClick={() => setPage(page + 1)}>Lataa lisää</button>
        </div>
      )}
    </main>
  );
};

export default ArticleList;
