import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import axios from "axios";
import "../styles.css"; // Ensure this path matches your project structure

const Profile = () => {
  const { user, loading } = useAuth();
  const [deleteStatus, setDeleteStatus] = useState(null);

  const handleDeleteMessages = async () => {
    if (window.confirm("Are you sure you want to delete all your messages?")) {
      try {
        setDeleteStatus("Deleting...");
        const token = await user.getIdToken();
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/api"
            : "/api"; // Replace with your production domain

        const response = await axios.delete(`${apiUrl}/messages/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDeleteStatus(
          `Successfully deleted ${response.data.deleted_count} messages`
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      } catch (error) {
        console.error("Error deleting messages:", error);
        setDeleteStatus(
          error.response?.data?.error || "Error deleting messages"
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      try {
        setDeleteStatus("Deleting account...");
        const token = await user.getIdToken();
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/api"
            : "/api"; // Replace with your production domain

        await axios.delete(`${apiUrl}/user/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDeleteStatus("Account deleted successfully");
        setTimeout(() => {
          setDeleteStatus(null);
          signOut(auth);
        }, 3000);
      } catch (error) {
        console.error("Error deleting account:", error);
        setDeleteStatus(
          error.response?.data?.error || "Error deleting account"
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="profile-container">
        <p className="error-message">Please log in to view your profile.</p>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h2 className="profile-title">User Profile</h2>
      {user.photoURL ? (
        <img
          src={user.photoURL}
          alt="Profile"
          className="profile-image"
          onError={(e) => {
            console.error("Profile image failed to load:", user.photoURL);
            e.target.style.display = "none"; // Hide broken image
          }}
        />
      ) : (
        <div
          className="profile-image placeholder"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            backgroundColor: "#4a90e2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "2rem",
            marginBottom: "10px",
          }}
        >
          {user.displayName?.charAt(0) || "A"}
        </div>
      )}
      <p className="profile-info">
        <strong>Name:</strong> {user.displayName || "Anonymous"}
      </p>
      <p className="profile-info">
        <strong>Email:</strong> {user.email}
      </p>

      <div className="button-container">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
        <button className="delete-button" onClick={handleDeleteMessages}>
          Delete All Messages
        </button>
        <button className="delete-button" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>

      {deleteStatus && (
        <p
          className={`status-message ${
            deleteStatus.includes("Error") ? "error" : "success"
          }`}
        >
          {deleteStatus}
        </p>
      )}
    </div>
  );
};

export default Profile;
