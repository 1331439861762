import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDnUpnkOnM39PoSPX1krVI_Pae2hUOuQUc",
  authDomain: "uutislauta.firebaseapp.com",
  projectId: "uutislauta",
  storageBucket: "uutislauta.firebasestorage.app",
  messagingSenderId: "218566853477",
  appId: "1:218566853477:web:049d1df6dc43c40f57178c",
  measurementId: "G-F6QJK4XYBK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Authentication
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
